import { Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";

import { CbhIcon } from "../../components/CbhIcon";
import { DistanceText } from "../../components/DistanceText";
import { DotSeparatedList } from "../../components/DotSeparatedList";

interface WorkplaceProfileHeadingProps {
  name: string;
  type: string;
  distanceText?: string;
  distanceIsLoading: boolean;
  distanceIsError: boolean;
  averageRating?: number;
}
export function WorkplaceProfileHeading(props: WorkplaceProfileHeadingProps) {
  const { name, averageRating, type, distanceText, distanceIsLoading, distanceIsError } = props;

  return (
    <Box>
      <Title component="h3" variant="h3" sx={{ marginBottom: 4 }}>
        {name}
      </Title>

      <DotSeparatedList>
        <Text variant="body1" color={(theme) => theme.palette.text.secondary}>
          {type}
        </Text>

        {isDefined(averageRating) && (
          <Box component="span">
            <CbhIcon
              type="star-filled"
              size="xSmall"
              sx={{
                color: (theme) => theme.palette.primary.main,
                marginRight: 1,
                verticalAlign: "middle",
                marginBottom: "0.125rem",
              }}
            />

            <Text
              variant="body1"
              color={(theme) => theme.palette.text.secondary}
              sx={{ display: "inline", verticalAlign: "baseline" }}
            >
              {averageRating}
            </Text>
          </Box>
        )}

        <DistanceText
          isError={distanceIsError}
          isLoading={distanceIsLoading}
          formattedDistance={distanceText}
          variant="body1"
          color={(theme) => theme.palette.text.secondary}
        />
      </DotSeparatedList>
    </Box>
  );
}
