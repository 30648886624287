import { type SxProps, type Theme } from "@mui/material";
import { useFacilityPhotos } from "@src/appV2/Facilities/api/useFacilityPhotos";
import { type Location } from "@src/appV2/lib/GoogleMaps";

import { CopyToClipboardProvider } from "../../components/CopyToClipboard/useCopyToClipboard";
import { HorizontalScrollView } from "../../components/HorizontalScrollView";
import { MapWithAddress } from "../../GeoLocation/MapWithAddress";

interface WorkplaceProfileLocationAndGalleryProps {
  workplaceId: string;
  location: Location;
  scrollViewOuterPaddingCompensation?: number;
  scrollViewSx?: SxProps<Theme>;
}

export function WorkplaceProfileLocationAndGallery(props: WorkplaceProfileLocationAndGalleryProps) {
  const { workplaceId, location, scrollViewSx, scrollViewOuterPaddingCompensation } = props;

  const { data: photos, isSuccess: photosIsSuccess } = useFacilityPhotos({
    facilityId: workplaceId,
  });

  return (
    <CopyToClipboardProvider>
      <HorizontalScrollView
        stretch
        outerPaddingCompensation={scrollViewOuterPaddingCompensation}
        sx={scrollViewSx}
      >
        <MapWithAddress location={location} />

        {photosIsSuccess &&
          photos.photosList.map((photo) => (
            <img key={photo._id} src={photo.url} alt={photo.name} loading="lazy" />
          ))}
      </HorizontalScrollView>
    </CopyToClipboardProvider>
  );
}
