import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { type Location } from "@src/appV2/lib/GoogleMaps";

import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { DialogHeader } from "../../components/DialogHeader";
import { SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH } from "../../paths";
import { useWorkplaceModalsDataContext } from "../../useWorkplaceModalsDataContext";
import { WorkplaceCheckInInstructionsCard } from "./CheckInInstructions";
import { WorkplaceHighlightsAndReviewsCard } from "./HighlightsAndReviewsCard";
import { WorkplaceImportantInformationCard } from "./ImportantInformationCard";
import { WorkplaceProfileOverviewSection } from "./OverviewSection";
import { WorkplacePoliciesCard } from "./PoliciesCard";

interface WorkplaceProfileModalProps {
  modalState: UseModalState;
  workplace: Facility;
}

export function WorkplaceProfileModal(props: WorkplaceProfileModalProps) {
  const { modalState, workplace } = props;
  const {
    name,
    userId: workplaceId,
    type,
    phone,
    geoLocation,
    fullAddress,
    lateCancellation,
    requiresLunchBreak,
  } = workplace;

  const { generateModalPath } = useWorkplaceModalsDataContext();

  const location: Location = { lat: geoLocation?.coordinates[1], lng: geoLocation?.coordinates[0] };

  return (
    <FullScreenDialog modalState={modalState} variant="primary">
      <DialogContent sx={{ px: 7, pt: 0 }}>
        <DialogHeader />

        <WorkplaceProfileOverviewSection
          name={name}
          type={String(type)}
          workplaceId={String(workplaceId)}
          phoneNumber={phone}
          location={location}
          formattedAddress={fullAddress?.formatted}
        />

        <Stack spacing={5}>
          <WorkplaceHighlightsAndReviewsCard workplaceId={String(workplaceId)} />
          <WorkplaceImportantInformationCard workplaceId={String(workplaceId)} />
          <WorkplacePoliciesCard
            workplaceId={String(workplaceId)}
            lateCancellation={lateCancellation}
            requiresLunchBreak={requiresLunchBreak}
          />
          <WorkplaceCheckInInstructionsCard workplaceId={String(workplaceId)} />
        </Stack>
      </DialogContent>

      <DialogFooter
        withShadow
        orientation="horizontal"
        backgroundColor={(theme) => String(theme.palette.background.tertiary)}
        onClose={() => {
          modalState.closeModal();
        }}
      >
        <Button
          fullWidth
          variant="contained"
          size="large"
          sx={{ maxWidth: "60%" }}
          href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
            workplaceId: String(workplaceId),
          })}
        >
          View open shifts
        </Button>
      </DialogFooter>
    </FullScreenDialog>
  );
}
