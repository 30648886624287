import { Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { WorkerAvatar } from "@src/appV2/ShiftDiscovery/components/WorkerAvatar";
import { formatDistanceToNow } from "date-fns";

interface WorkplaceReviewCommentAuthorInfoProps {
  workerName: string;
  workerId?: string;
  createdAt: Date;
}

export function WorkplaceReviewCommentAuthorInfo(props: WorkplaceReviewCommentAuthorInfoProps) {
  const { workerName, workerId, createdAt } = props;

  return (
    <Stack direction="row" spacing={3}>
      <WorkerAvatar workerId={workerId} size="small" />

      <Box>
        <Text semibold variant="body2">
          {workerName}
        </Text>

        <Text variant="caption" color={(theme) => theme.palette.text.secondary}>
          {formatDistanceToNow(createdAt)} ago
        </Text>
      </Box>
    </Stack>
  );
}
