import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { type Location } from "@src/appV2/lib/GoogleMaps";
import { generatePath } from "react-router-dom";

import { IconButton } from "../../components/IconButton";
import { OpenGeoLocationBottomSheet } from "../../components/OpenGeoLocation/OpenGeoLocationBottomSheet";
import { useOpenGeoLocationContext } from "../../components/OpenGeoLocation/useOpenGeoLocation";

interface WorkplaceProfileActionsProps {
  workplaceId: string;
  phoneNumber?: string;
  location?: Location;
}

export function WorkplaceProfileActions(props: WorkplaceProfileActionsProps) {
  const { workplaceId, phoneNumber, location } = props;

  const { href, onClick } = useOpenGeoLocationContext();

  return (
    <Stack direction="row" spacing={3}>
      <IconButton
        invert
        iconType="chat"
        size="large"
        variant="contained"
        href={generatePath(DeprecatedGlobalAppV1Paths.CHAT, {
          facilityId: workplaceId,
        })}
      />
      {isDefined(phoneNumber) && (
        <IconButton
          invert
          iconType="phone"
          size="large"
          variant="contained"
          href={`tel:+1${phoneNumber}`}
        />
      )}
      {isDefined(location) && (
        <>
          <IconButton
            invert
            iconType="compass"
            size="large"
            variant="contained"
            href={href}
            onClick={onClick}
          />
          <OpenGeoLocationBottomSheet />
        </>
      )}
    </Stack>
  );
}
